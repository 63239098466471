import { default as indexFCBv1Fu6ayMeta } from "/opt/build/repo/pages/activity/[parent]/[slug]/index.vue?macro=true";
import { default as indexCvqKFUPAkdMeta } from "/opt/build/repo/pages/activity/[parent]/index.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as newsletter4iem4JeVdNMeta } from "/opt/build/repo/pages/newsletter.vue?macro=true";
export default [
  {
    name: "activity-parent-slug___it",
    path: "/:parent()/:slug()",
    component: () => import("/opt/build/repo/pages/activity/[parent]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "activity-parent___it",
    path: "/:parent()",
    component: () => import("/opt/build/repo/pages/activity/[parent]/index.vue").then(m => m.default || m)
  },
  {
    name: "index___it",
    path: "/",
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "newsletter___it",
    path: "/newsletter",
    component: () => import("/opt/build/repo/pages/newsletter.vue").then(m => m.default || m)
  }
]